import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppButton from '../../../components/ui/AppButton/AppButton';
import MuiIcons from '../../../components/ui/MuiIcons/MuiIcons';
import Nestable from 'react-nestable';
import './CourseStructurePanel.scss';
import CustomTextField from '../../../components/ui/CustomTextField/CustomTextField';
import CustomCheckbox from '../../../components/ui/CustomCheckbox/CustomCheckbox';
import * as courseInfoAction from '../../../redux/actions/courseInfoAction';
import useToastNotifications from '../../../utils/hooks/useToastNotifications';
import CustomSweetAlert from '../../../components/ui/CustomSweetAlert/CustomSweetAlert';
// import DataHelper from '../../../utils/DataHelper';


const CourseStructurePanel = () => {
  // getting course info from redux
  const courseInfo = useSelector((state) => state.courseInfo);
  const dispatch = useDispatch();
  const { showWarning } = useToastNotifications();

  // managing local states for topics and actions
  const [crsStr, setCrsStr] = useState(courseInfo?.rawCrsStructure || []);
  const [editingItem, setEditingItem] = useState(null);
  const [addingItem, setAddingItem] = useState(null);
  const [deletingItem, setDeletingItem] = useState(null);
  const [reorder, setReorder] = useState(false);
  // const autoScrollInstance  = DataHelper.createAutoScroll('#crs-layoutss', showWarning, window.innerHeight * 0.3, 20);


  useEffect(() => {
    setCrsStr(courseInfo?.rawCrsStructure)
  }, [courseInfo?.rawCrsStructure])


  // handler for changing title during editing
  const handleTitleChange = useCallback((e) => {
    const value = e.target.value;
    if (addingItem) {
      setAddingItem((prev) => ({ ...prev, title: value }));
      return;
    }
    setEditingItem((prev) => ({ ...prev, title: value }));
  }, [addingItem]);


  // saving the updated title
  const handleTitleSave = useCallback(() => {
    if (editingItem && !addingItem) {
      const updatedItems = updateStructure(crsStr, { title: editingItem.title }, editingItem.id);
      setCrsStr(updatedItems);
      let payload = {
        action: 2,
        payload: {
          struct: idMap(crsStr),
          id: editingItem.id,
          topicname: editingItem.title,
          configid: window.ce.rlm.config_id
        }
      }
      dispatch(courseInfoAction.updateStructureRequest(payload));
    } else {
      let payload = {
        action: 1,
        payload: {
          struct: idMap(crsStr),
          user_id: window?.ce?.platform_scorm?.userId,
          topicname: addingItem?.title,
          courseid: courseInfo?.supplementaries?.list[0],
          duration: 5,
          status: 1,
          configid: window.ce.rlm.config_id
        }
      }
      dispatch(courseInfoAction.updateStructureRequest(payload));
    }
    setEditingItem(null);
    setAddingItem(null);
  }, [editingItem, addingItem, crsStr, updateStructure]);

  // adding a new topic under the parent
  const handleAdd = useCallback(
    (parentItem) => {
      if (addingItem) {
        showWarning('Please update or delete the already added item.');
        return;
      }
      const newItem = { id: '10000', title: 'Enter New topic' };
      const updatedItems = updateStructure(crsStr, { children: [...(parentItem.children || []), newItem] }, parentItem.id);
      setCrsStr(updatedItems);
      setAddingItem(newItem);
    },
    [addingItem, crsStr, showWarning, updateStructure]
  );

  // setting the topic for editing
  const handleEdit = useCallback((item) => {
    setEditingItem({ id: item.id, title: item.title });
  }, []);

  const handleChange = useCallback(({ items, dragItem }) => {
    // autoScrollInstance.stopScrolling();
    setCrsStr(items);
    setReorder(dragItem)
  }, [])



  // confirming the deletion of a topic
  const handleDeleteConfirm = useCallback(() => {
    let payload = {
      action: 3,
      payload: {
        id: deletingItem?.id,
        configid: window?.ce?.rlm?.config_id,
        struct: idMap(crsStr)
      }
    }
    dispatch(courseInfoAction.updateStructureRequest(payload));
    setDeletingItem(null);
    setAddingItem(null);
  }, [crsStr, deletingItem]);


  const handleHide = (e) => {
    setReorder(e.target.checked);
    if(addingItem || deletingItem){
      setDeletingItem(null);
      setAddingItem(null);
    }
  };

  const reorderSave = () => {
    if (reorder?.id) {
      let payload = {
        action: 4,
        payload: {
          struct: idMap(crsStr),
          configid: window?.ce?.rlm?.config_id,
          id: reorder?.id,
          topicname: reorder?.title,
        },
      }
      dispatch(courseInfoAction.updateStructureRequest(payload));
    }
    setReorder(false)
  }

  const handleClose = (item) => {
    if (addingItem) {
      const updatedItems = removeItem(crsStr, addingItem?.id, false);
      setCrsStr(updatedItems);
      setAddingItem(null);
      setEditingItem(null);
      return;
    }
    setEditingItem(null);

  }


  // memoized function to render a topic
  const renderItem = useMemo(
    () => (props) => {
      const { item, handler, collapseIcon } = props;

      const isEditing = ((editingItem?.id ?? addingItem?.id) === item.id);
      return (
        <div className={`crs-topic p-2 child-${props.depth}`}>
          {(isEditing) ? (
            <div className="nested-item-content w-100">
              <CustomTextField
                placeholder="topic title"
                variant="outlined"
                name="title"
                className="text-area w-100"
                value={editingItem?.title ?? addingItem?.title}
                onChange={handleTitleChange}
              />
              <span className="icon-area">
                <MuiIcons iconName="check" onClick={handleTitleSave} data-tooltip='Save' data-position='bottom' />
                <MuiIcons iconName="close" onClick={() => handleClose(item)} data-tooltip='Close' data-position='bottom' />
              </span>
            </div>
          ) : (
            <>
              <div className="nested-item-content">
                {/* {handler} */}
                <MuiIcons iconName="drag" className="drag-handle" />
                {collapseIcon}
                <p>{item.title || 'untitled'}</p>
              </div>
              {!reorder && <span className="icon-area">
                <MuiIcons iconName="add" onClick={() => handleAdd(item)} data-tooltip='Add' data-position='bottom' />
                <MuiIcons iconName="edit" onClick={() => handleEdit(item)} data-tooltip='Edit' data-position='bottom' />
                <MuiIcons iconName="delete" onClick={() => { setDeletingItem(item) }} data-tooltip='Delete' data-position='bottom' />
              </span>}
            </>
          )}
        </div>
      );
    },
    [editingItem, handleTitleChange, handleTitleSave, handleAdd, handleEdit]
  );

  // memoized nested drag-and-drop component
  const dragTopics = useMemo(
    () => {
      try {
        return (
          <Nestable
            // handler={<MuiIcons iconName="drag" className="drag-handle" />}
            items={crsStr}
            renderItem={renderItem}
            onChange={handleChange}
            disableDrag={Boolean(editingItem || addingItem || !reorder)}
            renderCollapseIcon={({ isCollapsed }) =>
              isCollapsed ? <MuiIcons iconName="add" /> : <MuiIcons iconName="minus" />
            }
            confirmChange={({ dragItem, destinationParent }) => {
              // Prevent root-level drop
              if (!destinationParent) {
                return false;
              }
              if (dragItem?.id === destinationParent?.id) {
                console.error("Cannot make a parent its own child.");
                return false;
              }

              return true;
            }}
          // Uncomment and configure if auto-scroll is required
          // onDragStart={autoScrollInstance.startScrolling}
          // onDragEnd={autoScrollInstance.stopScrolling}
          />
        );
      } catch (e) {
        console.error('Error rendering drag-and-drop component:', e);
        return null;
      }
    }, [crsStr, renderItem]
  );

  const deleteWarning = useMemo(() => {
    return <div className='warning-popup danger-popup'>
      <CustomSweetAlert
        warning
        showCancel
        show={Boolean(deletingItem)}
        confirmBtnText="delete"
        confirmBtnBsStyle="danger"
        title={<p className="sweet-title">Are you sure you want to delete this topic?</p>}
        onConfirm={handleDeleteConfirm}
        onCancel={() => setDeletingItem(null)}
      />
    </div>
  }, [deletingItem])

  // rendering the course structure panel
  return (
    <div className="crs-structure-container p-4 pt-0">
      <div className="crs-str-header">
      {reorder?.id &&
          <AppButton id="editButton" theme="primary1_transparent" className="fade-in" onClick={reorderSave}>
            Save
          </AppButton>
        }
        <CustomCheckbox
          label={'Reorder'}
          checked={reorder}
          labelPlacement={'start'}
          className="reorder-ck"
          name="Topic Reorder"
          onChange={(e) => handleHide(e)}
        />
      </div>
      <div className='crs-structure' id="crs-layoutss"> {dragTopics} </div>
      {deleteWarning}
    </div>
  );
};






// helper function to update a topic in the structure
export const updateStructure = (items, updateItem, id) => {
  try {
    return items?.map((e) => {
      if (e?.id === id) {
        return { ...e, ...updateItem };
      }
      if (e.children) {
        return { ...e, children: updateStructure(e.children, updateItem, id) };
      }
      return e;
    });
  } catch (e) { console.error("Error in updateStructure:", e); }
};

// helper function to create id map for the structure
export const idMap = (items = []) =>
  items?.map(item => ({
    id: item?.id,
    ...(item?.children?.length > 0 && { n: idMap(item?.children) }),
  }));

export const removeItem = (items, id) =>
  items.filter((e) => e.id !== id)
    .map((e) =>
      e.children
        ? {
          id: e.id,
          ...e,
          ...({ children: removeItem(e.children, id) }),
        }
        : { id: e.id, ...(e) }
    );

export default CourseStructurePanel;
