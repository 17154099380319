import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
// import FocusLockWrapper from '../../../utils/hooks/FocusLockWrapper';

const StyledButton = styled.button`
  ${(props) => props?.colors && props.colors};
  ${(props) => props?.sizes && props.sizes};
  font-size: 16px;
  outline: none;
  transition: all 0.5s ease-in-out;
  border: none;
  white-space: nowrap;
  font-family:var(--global-font-family-medium);
  transition: background-color 0.3s ease, font-weight 0.3s ease;
  &:disabled {
    opacity: 0.5;
    cursor: initial;
  }
     &:focus {
    opacity: 0.8;
    font-weight: bold;
  }
`;

const AppButton = ({ onClick, id, className, size, style, tabIndex = 1, children, theme, disabled, ...rest }) => {
  const [ceTheme, setCeTheme] = useState(window?.ce?.theme || 'ce_theme');

  useEffect(() => {
    setCeTheme(window.ce?.theme || 'ce_theme');
  }, [window?.ce?.theme]);



  return (
    <StyledButton
      onClick={onClick}
      id={id}
      className={className}
      colors={getButtonTheme(theme, ceTheme)}
      style={style}
      sizes={sizes[size||'small']}
      disabled={disabled}
      {...rest}
    >
      {children}
    </StyledButton>
  );
};

AppButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
  theme: PropTypes.string,
  disabled: PropTypes.bool,
};

export const sizes={
  small:css`
    padding:3px 10px;
    min-width:80px;
    min-height:35px;
  `,
  medium:css`
   padding:5px 20px;
   min-width:123px;
   min-height:45px;
 `,  
  large:css`
    padding:14px 16px;
    min-width:140px;
  `
}

export const buttonTextColor = {
  'skill_theme': {
    primary2:{
      color:'#fff',
      background_color:"var(--primary-color-1)"
    } 
  },
  ce_theme: {
    primary2:{
     color: '#000'
    } 
  }
};


  // define a helper function to generate buttonTheme dynamically based on Theme
  export const getButtonTheme = (theme,ceTheme) => {
    return {
      primary1: css`
        border-radius: var(--primary-radius-1);
        background-color: var(--primary-color-1);
        color: #fff !important;
        &:hover {
          opacity: 0.8;
        }
      `,
      primary1_transparent: css`
        border-radius: var(--primary-radius-1);
        background-color: transparent;
        border: 0.2rem solid var(--primary-color-1) !important;
        color: var(--primary-color-1) !important;
        &:hover {
          background-color: var(--primary-color-1);
          color: #fff !important;
        }
      `,
      primary2: css`
        border-radius: var(--primary-radius-1);
        background-color: ${buttonTextColor[ceTheme]?.primary2?.background_color || `var(--primary-color-2)`};
        // custom text color change based on theme
        color: ${buttonTextColor[ceTheme]['primary2']['color'] || '#fff'} !important;
        &:hover {
          opacity: 0.8;
        }
      `,
      primary2_transparent: css`
        border-radius: var(--primary-radius-1);
        background-color: transparent;
        border: 0.2rem solid var(--primary-color-2) !important;
        color: var(--primary-color-2) !important;
        &:hover {
          background-color: var(--primary-color-2);
          color: #fff !important;
        }
      `,
      secondary1: css`
        border-radius: var(--primary-radius-1);
        background-color: var(--secondary-color-1);
        color: #fff !important;
        &:hover {
          opacity: 0.8;
        }
      `,
      secondary2: css`
        border-radius: var(--primary-radius-1);
        background-color: var(--secondary-color-2);
        color: #fff !important;
        &:hover {
          background-color: var(--primary-color-1);
        }
      `,
    }[theme || 'primary1']; // default to 'primary1' if theme is not provided
  };

export default AppButton;
