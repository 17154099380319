import React, { useEffect, useState, useCallback } from 'react';
import './catchgame.scss';
// import leaf from "../../../assets/img/leaf.png";
// import bike from "../../../assets/img/bike.png";
// import goat from "../../../assets/img/ship.png";
import AppButton from "../../ui/AppButton/AppButton";
import DataHelper from '../../../utils/DataHelper';
import Confetti from "react-confetti";

const CatchGame = ({bg_image,gameSpeed, ...props}) => {

    const [gameState, setGameState] = useState({
        state: 0, // 0 - start, 1 - play, 2 - game over
        bucketPosition: 310, // Starting position of the goat
        objectPositions: [],
        score: 0,
        gameIntervalId: null,
        currentQuestionIndex: 0,
        countdown: null,
        stopConfetti:false
    });
    const gameArea = 1000;
    const { state, bucketPosition, objectPositions, score, gameIntervalId, currentQuestionIndex, countdown } = gameState;
    // Get current question
    const currentQuestion = props?.items?.[currentQuestionIndex] || {};
    
    // Update object positions when items are passed as props or when question changes
    useEffect(() => {
        if (props?.items) {
            const initialObjectPositions = resetObjects(props?.items[currentQuestionIndex]?.op?.length || 0);
            setGameState(prev => ({
                ...prev,
                objectPositions:initialObjectPositions,
            }));
        }
    }, [props?.items, currentQuestionIndex]);

    

    // Start the game
    useEffect(() => {
        if (countdown) return;
        if (state === 1) startGame();

        return () => {
            clearInterval(gameIntervalId);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [state, countdown]);

    // Handle bucket movement
    const handleKeyDown = (event) => {
      setGameState((prev) => {
        const newBucketPosition = event.key === 'ArrowLeft' && prev.bucketPosition > 0
        ? prev.bucketPosition - 20
        : event.key === 'ArrowRight' && prev.bucketPosition < gameArea
            ? prev.bucketPosition + 20
            : prev.bucketPosition;
        return {
          ...prev, // spread the previous state
          bucketPosition: newBucketPosition, // update the bucketPosition
        };
      });
      
    };

    // Game loop
    const startGame = () => {
        try{
        clearInterval(gameIntervalId);
          if(!gameState?.objectPositions?.length > 0) return; 
          const intervalId = setInterval(() => {
            setGameState(prev => {
                // Check if objects need to be reset
                const needsReset = prev?.objectPositions[0]?.top >= 1000;
    
                // Reset or update object positions
                const updatedPositions = needsReset
                    ? resetObjects(prev?.objectPositions.length)
                    : prev?.objectPositions?.map(position => moveObject(position));
                return {
                    ...prev,
                    objectPositions: updatedPositions,
                };
            });
    
            // Check for collisions
            checkCollision();
        }, 20);

        setGameState((prev) => ({
            ...prev,
            gameIntervalId: intervalId,
        }));

        document.addEventListener('keydown', handleKeyDown);
    }catch(e){}
    };

    // Move falling objects
    const moveObject = useCallback((position) => {
        return { ...position, top: position.top + (gameSpeed??2) };
    },[gameSpeed])

/**
 * Splits a maximum value into count random parts and returns their cumulative sums.
 * Ensures the sum of parts equals the maximum value by adjusting for rounding errors.
 * 
 * @param {number} max - The maximum value to split.
 * @param {number} count - The number of parts to split the value into.
 * An array of cumulative sums.
 */
const splitNumber = (max, count, minSpacing = 50, currentQuestion = { op: [] }) => {
    if (count <= 0 || max <= 0) return [];
  
    // Generate random weights and normalize
    const weights = Array.from({ length: count }, () => Math.random());
    const totalWeight = weights.reduce((sum, w) => sum + w, 0);
    const normalizedWeights = weights.map((w) => w / totalWeight);
  
    // Calculate dynamic spacing based on text length
    const spacings = currentQuestion.op.map(
      (item) => (item?.length > 12 ? 250 : 150)
    );
  
    // Convert weights to rounded parts
    const parts = normalizedWeights.map((w, i) => {
      const spacing = spacings[i] || minSpacing; // Fallback to minSpacing if no currentQuestion
      return Math.round(w * (max - (count - 1) * spacing));
    });
  
    // Adjust for rounding errors
    let diff =
      max -
      parts.reduce((sum, part) => sum + part, 0) -
      spacings.reduce((sum, s, i) => (i > 0 ? sum + s : sum), 0);
    for (let i = 0; diff !== 0 && i < count; i++) {
      const adjustment = diff > 0 ? 1 : -1;
      parts[i] += adjustment;
      diff -= adjustment;
    }
  
    // Calculate cumulative positions with spacing
    return parts.reduce((acc, part, index) => {
      const lastPosition = acc.length ? acc[acc.length - 1] : 0;
      const spacing = spacings[index] || minSpacing; // Use dynamic spacing
      acc.push(lastPosition + part + (index > 0 ? spacing : 0));
      return acc;
    }, []);
  };
  


    // Reset positions for multiple objects with unique left positions
    // const resetObject = (usedPositions) => {
    //     const minDistance = 250; // Minimum distance between positions
    //     const maxPosition = gameArea - minDistance; // Valid range for left positions
    //     let leftPosition;
    //     console.log(usedPositions,"usedPositionsusedPositions");
        

    //     do {
    //         leftPosition = Math.floor(Math.random() * maxPosition);
    //     } while (usedPositions.some(pos => Math.abs(pos - leftPosition) < 250));

    //     return { top: -50, left: leftPosition };
    // };


    const resetObjects = (numObjects) => {
        // const positions =splitNumber(); // To store the unique left positions
        let minDistance = 150; // Minimum distance between positions
        const maxPosition = gameArea - minDistance; // Valid range for left positions
        const positions =splitNumber(maxPosition,numObjects);
        console.log(positions,"positions",numObjects);
        
        // // Generate unique positions using do...while loop
        // while (positions.length < numObjects) {
        //     let leftPosition;
        //     // minDistance=currentQuestion.op[positions?.length].length*10+150
        //     // Try to find a valid position
        //     do {
                
        //         leftPosition = Math.floor(Math.random() * maxPosition);
        //     } while (positions.some(pos => Math.abs(pos - leftPosition) < minDistance));
    
        //     // If valid, add it to positions
        //     positions.push(leftPosition);
        // }
    
        // Return an array of objects with fixed top position and unique left positions
        return positions.map(left => ({ top: -50, left }));
    };


    // Collision check
    const checkCollision = () => {
        const bucketRect = document.getElementById('goat')?.getBoundingClientRect();
        const optionRects = objectPositions.map((_, i) => document.getElementById(`object-${i}`)?.getBoundingClientRect());

        if (!bucketRect) return;

        let isCollidingWithCorrectOption = false;
        let isCollidingWithAnyOption = false;

        // Check for collisions in a single loop
        optionRects.forEach((rect, i) => {
            if (rect) {
                const isColliding = !(
                    bucketRect.left + bucketRect.width < rect.left ||
                    bucketRect.left > rect.left + rect.width ||
                    bucketRect.top + bucketRect.height < rect.top ||
                    bucketRect.top > rect.top + rect.height
                );

                if (isColliding) {
                    isCollidingWithAnyOption = true; // At least one option is colliding
                    if (i === currentQuestion?.ans[0]) { 
                        isCollidingWithCorrectOption = true; // Found a collision with the correct option
                    }
                }
            }
        });

        // Handle scoring and question change based on collision checks
        if (isCollidingWithCorrectOption) {
            setGameState((prev) => ({
                ...prev,
                score: prev.score + 1, // Increment score
            }));
            handleNextQuestion();
        } else if (isCollidingWithAnyOption) {
            handleNextQuestion(); // Move to next question for any collision
        }
    };


    // Go to the next question
    const handleNextQuestion = () => {
        setGameState((prev) => {
            const nextIndex = prev.currentQuestionIndex + 1; // Calculate the next question index
            const hasMoreQuestions = nextIndex < props?.items?.length; // Check if there are more questions
    
            // reset the object positions for the next question
            const newObjectPositions = hasMoreQuestions 
                ? resetObjects(props?.items[nextIndex]?.op?.length) 
                : [];
    
            if (!hasMoreQuestions) {
                clearInterval(prev.gameIntervalId); 
    
                // schedule confetti stop after 15 seconds
                setTimeout(() => {
                    setGameState((prevState) => ({
                        ...prevState,
                        stopConfetti: true,
                    }));
                }, 15000);
                return {
                    ...prev,
                    state: 2, // set state to Game Over
                    currentQuestionIndex: 0, // reset question index for next game
                    objectPositions: newObjectPositions,
                };
            }
            // proceed to the next question
            return {
                ...prev,
                currentQuestionIndex: nextIndex, 
                objectPositions: newObjectPositions,
            };
        });
    };
    

    // Reset the game
    const resetGame = () => {
        clearInterval(gameIntervalId);
        const initialObjectPositions = resetObjects(props?.items[0]?.op?.length || 0);
        setGameState({
            state: 0,
            bucketPosition: 310,
            objectPositions:initialObjectPositions,
            score: 0,
            gameIntervalId: null,
            currentQuestionIndex: 0,
            countdown: null,
        });
    };

    // Handle game start
    const handleStartClick = () => {
        let count = 3;
        setGameState((prev) => ({ ...prev, countdown: count, state: 1 }));

        const countdownInterval = setInterval(() => {
            count -= 1;
            setGameState((prev) => ({ ...prev, countdown: count }));
            if (count === 0) clearInterval(countdownInterval);
        }, 1000);
    };


    const startBoard = () => {
        return (
            <div className="game-screen">
                <div className='board'>
                    <h2>Welcome to Target Capture Challenge Game!</h2>
                    <p>Catch the correct items in your bucket. Avoid the wrong items!</p>
                    <AppButton theme="primary1_transparent" onClick={handleStartClick} >Start Game</AppButton>
                </div>
            </div>
        );
    }

    const getBgImageUrl = (img) => {
        return DataHelper.getResourcePath(0, img);
      }

    /**
  * Main rendering logic based on game state.
  */
    const renderGameContent = useCallback(() => {
        switch (state) {
            case 0:
                return startBoard();
            case 1:
                // currentQuestion = items[currentQuestionIndex]
                return (
                    <>
                        <div className="road">
                            <div className="goat" id="goat" style={{ left: `${bucketPosition}px` }}>
                                <img src={''} alt="Goat" />
                            </div>

                            {!countdown && objectPositions?.length > 0 ? <>
                                {objectPositions.map((position, i) =>{
                                        return(<div
                                        key={i}
                                        id={`object-${i}`}
                                        className="object leaf falling-object"
                                        style={{ top: `${position.top+(15*i)}px`, left: `${position.left+(50*i)}px` }}
                                    >
                                        {currentQuestion.op[i]}
                                    </div>)}
                                )}</> : <div className='game-screen'> <h4 className="countdown">Starting in: {countdown}</h4> </div>}
                        </div>
                        <h2 className="question" dangerouslySetInnerHTML={{ __html: currentQuestion?.question }}/>
                    </>
                );
            case 2:
                return (
                    <div className="game-screen">
                        <div className='board'>
                            <h2>Game Over!</h2>
                            <p>Your Score: {props?.items?.length} out of {score}</p>
                            {!gameState?.stopConfetti && <Confetti 
                                numberOfPieces={400} 
                                width={1800} 
                                height={550} 
                            />}
                            <AppButton theme="primary1_transparent" onClick={resetGame} >Play Again</AppButton>
                        </div>
                    </div>
                );
            default:
                return startBoard();
        }
    }, [countdown, objectPositions, bucketPosition, currentQuestion, gameState]);

    return (
        <div className='game-container' style={{ '--catch-bg-image':`url(${getBgImageUrl(bg_image)})`}}>
            <div className="game-area" style={{ width: `${gameArea + 150}px` }}>
                {renderGameContent()}
            </div>
        </div>
    );
};

export default CatchGame;
