import React, { createRef, useEffect, useRef, useState,useMemo,useCallback } from 'react';
import './home.scss'
import PropTypes from 'prop-types';
import { useHistory, withRouter } from 'react-router-dom';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { useSelector, useDispatch } from 'react-redux';
import * as courseInfoAction from '../../redux/actions/courseInfoAction';
import * as userInfoAction from '../../redux/actions/userInfoAction';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Celogo from '../../assets/img/ce_author.png'
import CePlayer from '../../assets/img/ce_logo_skill_comp.png'
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import TreeMenu from '../../components/ui/TreeMenu/TreeMenu';
import PoweredBy from '../../assets/img/powered_by.png'
import DataHelper from '../../utils/DataHelper';
import QuickTour from '../../components/ui/QuickTour/QuickTour';
import preval from 'preval.macro';
import PlayerConstants from '../../utils/PlayerConstants';
import Loader from '../../components/ui/Loader/Loader'
import * as utilityAction from '../../redux/actions/utilityAction';
import CustomSweetAlert from '../../components/ui/CustomSweetAlert/CustomSweetAlert';
import SocketConnection from '../../SocketServices';
import AppButton from '../../components/ui/AppButton/AppButton'
import SkillHome from './SkillHome';
import BuildHelper from '../../utils/BuildHelper';
import Quizzes from '../../components/content/Quizzes/Quizzes'
import CommonModal from '../../components/ui/CommonModal/CommonModal'
import  * as courseTrackingAction from '../../redux/actions/courseTrackingAction';
import t from '../../translation/useTranslate';
import * as ceAssetsInfoAction from '../../redux/actions/ceAssetsInfoAction';
import { changeLanguage, initializeI18n } from '../../translation/i18n';
import MuiIcons from '../../components/ui/MuiIcons/MuiIcons'
import FlagIcon from '../../components/ui/FlagIcon/FlagIcon';
import CustomCheckbox from '../../components/ui/CustomCheckbox/CustomCheckbox';

const TabPanel = (props) => {
        const { children, value, index, ...other } = props;
        return (
                <div
                        role="tabpanel"
                        hidden={value !== index}
                        id={`simple-tabpanel-${index}`}
                        aria-labelledby={`simple-tab-${index}`}
                        {...other}
                >
                        {
                                value === index && (
                                        <Box p={3}>
                                                <Typography component={'div'}>{children}</Typography>
                                        </Box>
                                )
                        }
                </div>
        );
}

TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
};

function a11yProps(index) {
        return {
                id: `simple-tab-${index}`,
                'aria-controls': `simple-tabpanel-${index}`,
        };
}

const useStyles = makeStyles((theme) => ({
        root: {
                flexGrow: 1,
                backgroundColor: theme.palette.background.paper,
        },
}));



const Home = () => {

        const dispatch = useDispatch();
        const homewrapper = createRef();
        const bgContent = useRef(null)
        const history = useHistory();
        const detailSection = useRef(null);
        const classes = useStyles();
        const [value, setValue] = useState(0);
        let htour = window.localStorage.getItem('home-tour')
        const { userInfo, globalStyle, utility, ceAssetsInfo } = useSelector(state => state)
        const [logoPath, setLogoPath] = useState(window.ce.isStandalone ? window.ce.siPath : (window?.ce?.ceuser?.role_id !== 4 ? Celogo : CePlayer));

        const userId = userInfo?.user?.uid || "";
        const clientId = userInfo?.user?.pvtid || "";
        const isConfiguredUser=(window?.ce?.ceuser?.role_id===4) && BuildHelper.isConfig();
        const type = "publish"// based on type path fetch(type)
       // Using the custom hook to manage WebSocket connection
       const { connect, response, error } = SocketConnection(userId, clientId, type);
        const warning={
                1:{
                title:"The author made some updates, so please relaunch and continue.",
                content:"",
                cButton:"Ok",
                function:()=>{
                        window.location.reload();
                        dispatch(utilityAction.socketWarning(0));
                }}
        }

        useEffect(() => {
                try {
                        if(BuildHelper.isLearner()){
                                const clientId = userInfo?.user?.pvtid;
                                const fileExtension = userInfo?.user?.logo ? userInfo?.user?.logo.split(".").pop() : 'png';
                                //added 222 for ingevity clinet in staging 
                                if (clientId && fileExtension && Number(clientId) !== 139 && Number(clientId) !== 222) {

                                        setLogoPath('https://dg7zdxz92ywv.cloudfront.net/platform/clientlogo/' + clientId + '.' + fileExtension);
                                }
                        }
                } catch { }
                /* 
                * In author mode, the socket is enabled only after after the chrome job completes.
                * In user mode, after publishing (cron job ends), we trigger an auto-refresh (connection always open if configured crs).
                */
                if (isPublished() || isConfiguredUser) { 
                        connect();
                }
        }, [userInfo, window?.ce?.platform_scorm?.ispublished])



        const isCEAuthor = () => {
                // return (window?.ce?.ceuser?.role_id === 1 || window?.ce?.ceuser?.role_id === -1) ? true : false
                return (window?.ce?.ceuser?.role_id !== 4) ? true : false
        }

        const isPublished = () => {
                const platform = window?.ce?.platform_scorm || {};
                return (!platform?.ispublished && platform?.isconfigured && isCEAuthor());
        }

        // const [btnText, setBtnText] = useState(isCEAuthor() ? (!DataHelper.isCourse() ? "Start Customizing" : (isPublished() ? "Publishing..." : "Start Editing")) : Number(userInfo?.user?.pvtid) === 269 ? "BEGIN ANALYSIS" : "Start");
        const [showAlert, setShowAlert] = useState(false);
        const [issueNode, setIssueNode] = useState([])
        const [isPreTest, setIsPreTest] = useState([]);
        const [isPreTestCompleted, setIsPreTestCompleted] = useState(false);
        const [hasAttestation, setHasAttestation] = useState({});
        const [languages, setLanguages] = useState([]);
        const [selectedLan, setSelectedLan] = useState({});
        const trackInfo = useSelector(state => state.courseTracking);
        const courseInfo = useSelector(state => state.courseInfo);


        const handleChange = (event, newValue) => {
                setValue(newValue);
        };


        const handleClick = useCallback(() => {
                const latestTrackInfo = { ...trackInfo }; 
                dispatch(courseTrackingAction.saveCourseProgress({...latestTrackInfo}));
                if (!BuildHelper.isOnScorm() && trackInfo?.courseTracking)DataHelper.lmsPostMessage(trackInfo?.courseTracking);
                const wrapper = homewrapper.current;
                wrapper.classList.toggle('resume-course');
                bgContent.current.classList.toggle('hidden-bg');

                setTimeout(() => {
                        history.push('/course');
                }, 800)
        },[trackInfo, dispatch, history, homewrapper, bgContent])
        
        const handleOk = () => {
                setShowAlert(false);
        };

        const infoInformation = () => {
                try {
                        // application information popup in contentenablers ---> hidden s click
                        function applicationInfo() {
                                const globalFont = globalStyle?.comspace?.gfs || 24;
                                // application URL
                                const url = PlayerConstants?.API_DESTINATION_STAGING?.split("/")[2] || ""
                                // last build update date 
                                let buildTimestamp = new Date(preval`module.exports = new Date().toLocaleString();`).toLocaleString('en-US', {
                                        month: 'long',
                                        day: 'numeric',
                                        year: 'numeric',
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        hour12: true
                                });
                                return <span className='info-details'>
                                        <p>CEAuthor : {PlayerConstants?.VERSION || ""} </p>
                                        <p>Player : {PlayerConstants?.VERSION || ""} </p>
                                        <p>Last build : {buildTimestamp} </p>
                                        <br />
                                        <p>Course id: {window?.ce?.rlm?.config_id || 139} </p>
                                        <p>Packaged: {String(window?.ce?.isScormPack) ?? false} </p>
                                        <p>Video Streaming: false </p>
                                        <br />
                                        <p>Lesson location: {trackInfo?.location_id ?? "0000"} </p>
                                        <p>API: {url || 139} </p>
                                        <br />
                                        <h2>Fonts</h2>
                                        <p>Global: {globalFont}, Mobile (&lt;900) : {DataHelper?.getGlobalFontSize(globalFont, 700)}, Tablet & Higher(&gt;900) : {DataHelper?.getGlobalFontSize(globalFont, 1999)}</p>
                                </span>
                        }

                        return <>
                                {/* in player mode application info in edit mode node issue info */}
                                {isCEAuthor() ? issueNode && issueNode.map((ele, index) => (
                                        <p key={index}>{ele?.node?.title + " ----> "} {ele?.issues?.countMismatch && (
                                                <span>Issues: Count Mismatch</span>
                                        )}

                                                {ele?.issues?.hasNull && (
                                                        <span>,hasNull</span>
                                                )}&nbsp;
                                                {ele?.issues?.hasDuplicateCompId && (
                                                        <span>,hasDuplicateCompId</span>
                                                )}&nbsp; </p>
                                )) :
                                        applicationInfo()}
                        </>
                } catch (e) { }
        }



       /**
         * Effect to set the topic for resume and manage pre-test and attestation states.
         * 
         * This effect runs when the `courseInfo`, `trackInfo.courseTracking`, or `globalStyle?.pre_test` dependencies change.
         * 
         * Key functionalities:
         * 1. set the topic resume if the course has been visited.
         * 2. initialize the pre-test state if conditions are met.
         * 3. initialize the attestation state based on learner status and global configuration.
        */
        useEffect(() => {

                const { courseTracking } = trackInfo;
                // check if the course has been tracked and visited
                if (courseTracking?.visited && Object.keys(courseTracking.visited).length > 0) {
                        const flatten = courseInfo.flatten; // access the flattened course info
                        setTopicResume(flatten, courseTracking); // set the topic for resuming
                }

                 // check if this is the initial state for the learner and pre-test conditions are met
                const isLearner = BuildHelper.isLearner();
                const hasPreTest = globalStyle?.pre_test?.is && globalStyle?.pre_test?.d?.items?.length > 0;
                const hasAttestConfig = globalStyle?.attest?.is && globalStyle?.attest?.begin && !BuildHelper.hasPreAttestation();
                
                if (trackInfo?.isInitial ) {
                        // set pre-test if conditions are satisfied
                        if (hasPreTest) {
                        setIsPreTest(globalStyle.pre_test?.d?.items);
                        }
                        
                        // set attestation if conditions are satisfied
                        if (hasAttestConfig) {
                        setHasAttestation({ is: true });
                        }
                }
        }, [courseInfo, trackInfo.courseTracking, globalStyle?.pre_test]);

        // auth making it false
        const handleAuth = () => {
                let topWindow = (window.top === window.self) ? false : true;
                if (topWindow) {
                        window.top.close();
                } else {
                        window.close();
                }
                dispatch(utilityAction.authFault(false));
        }


        const setTopicResume = (flatten, crsTracking) => {
                let nodeId = crsTracking.visited.last;
                if (nodeId) {
                        // if(Object.keys(courseInfo.topic).length > 0){
                        //         if(courseInfo.topic.nodeId !== nodeId){
                        //           console.log("Last Visited topic Set:"+nodeId,courseInfo);
                        //           dispatch(courseInfoAction.selectedTopic(flatten[nodeId]));
                        //         }
                        // }
                        // if (flatten[nodeId]) {
                                // setBtnText(isCEAuthor() ? (!DataHelper.isCourse() ? "Resume Customizing" : (isPublished() ? "Publishing..." : "Resume Editing")) : Number(userInfo?.user?.pvtid) === 269 ? "RESUME ANALYSIS" : "Resume Course")
                        // }
                }
        }

        const getCourseName = () => {
                const courseName = globalStyle?.title || courseInfo?.courseAttributes?.coursename || '';

                return globalStyle?.title?.length > 2
                        ? `${courseName}`
                        : (
                                <span>
                                      { t("WELCOME_TEXT")}:
                                        <span dangerouslySetInnerHTML={{ __html: courseName }} />
                                </span>
                        );
        };


        /** here topic is type ...bad naming  */
        const handleDrawer = (topic, width) => {
                dispatch(courseInfoAction.setDrawer({ value: true, topic, width }))
        }
        const printCourseMap = (e, type, width) => {
                handleDrawer(type, width)
        }

        useEffect(() => {
                const body = document.querySelector('body');
                body.classList.add('stop_scroll');
                if (!courseInfo?.drawerData?.value) body.classList.remove('stop_scroll');
        }, [courseInfo?.drawerData])

        const getImagePath = () => {
                const home_bg = "https://assets.contentenablers.com/website/imgs/home_video_poster_min_jpg_a7bfb35464.jpeg"
                return DataHelper.getResourcePath(0, globalStyle?.homeImage) || home_bg;
        }
        const findStructureIssue = (cData) => {
                if (!isCEAuthor()) {
                        setShowAlert(true)
                        return;
                }
                const resultNodes = DataHelper.checkContentIssues(cData);

                if (resultNodes.length > 0) {
                        setShowAlert(true)
                        setIssueNode(resultNodes)
                        console.log("Nodes with issues:", resultNodes);
                } else {
                        console.log("No issues in course data.");
                }
        }


        const ButtonText = React.useMemo(() => {
                // retrieve the last visited node ID from trackInfo
                const nodeId = trackInfo?.courseTracking?.visited?.last;
                const isStarted = courseInfo?.flatten[nodeId]; // represents whether user has started finding it
            
                // check if the course is currently being published
                const isPublish = isPublished(); // Function to determine if course is being published
                if (isCEAuthor()) {
                    // User is an author
                    if (!DataHelper.isCourse()) {
                        return isStarted ? t("RESUME_CUSTOMIZING") : t("START_CUSTOMIZING");
                    } else {
                        if (isPublish) {
                            // Course is being published
                            return `${t("PUBLISHING")}..`;
                        }
                        return isStarted ? "Resume Editing" : "Start Editing";
                    }
                } else {
                    // User is not an author
                    const userPvtid = userInfo?.user?.pvtid;
                    const flatten = courseInfo?.flatten||{}
                    if (Number(userPvtid) === 269)return isStarted ? t("RESUME_ANALYSIS") : t("BEGIN_ANALYSIS");

                    if(Number(flatten['1']?.status)===1||Number(flatten['1']?.p)===100) return t("RESUME_COURSE");
                   
                    return isStarted ? t("RESUME_COURSE") : t("START");
                }
            }, [courseInfo, trackInfo?.courseTracking, window?.ce?.platform_scorm?.ispublished, t()]);
            
            
        const updateProgress =(Progress) => {
                const trackData=Progress || trackInfo;
                dispatch(courseTrackingAction.saveCourseProgress(trackData));
        }

        const handleClose=()=>{
              dispatch(courseTrackingAction.skipPreTest());
              updateProgress();
              setIsPreTest([]);
        }

        const pretestCourseCompletion=(pass)=>{
                let prObj=trackInfo.courseTracking||{};
                if(pass)prObj=DataHelper?.shortcutCompletion(courseInfo?.flatten)?.t||{};
                let Progress={
                        ...trackInfo,
                        courseTracking:{...prObj}
                }
                updateProgress(Progress);
                setIsPreTestCompleted(true);
        }

        const handleAttestClose=()=>{
                localStorage.setItem(`${BuildHelper.configId()}_AT`, JSON.stringify(true)); 
                setHasAttestation();
        }
        const ceHome = () => {
                return (
                        <div style={{ '--ce-bg-image': `url(${getImagePath()})` }} className="home-container fluid-container" ref={homewrapper}>
                                {((htour === null) && !isCEAuthor() && !window.ce.isStandalone && t("START")) && !trackInfo?.hasLanguage && <QuickTour />}
                                <Box sx={{ flexGrow: 1 }}>
                                        <AppBar position="static">
                                                <Toolbar variant="dense">
                                                        <Typography variant="h6" color="inherit" component="div" className='img-container' style={{ cursor: 'pointer' }} >
                                                                <img src={logoPath} alt='' />
                                                        </Typography>
                                                </Toolbar>
                                        </AppBar>
                                </Box>
                                <div className="bg-content" ref={bgContent}>
                                        <div className='hero-overlay'></div>
                                        <div className='container'>
                                                <div className='row box-holder'>
                                                        <div className="box-item">
                                                                {!courseInfo.loading && <div>
                                                                        {/* <h3 className="crs-name">{ courseInfo.courseAttributes.coursename }</h3> */}
                                                                        <h3 className="crs-name" dangerouslySetInnerHTML={{ __html: courseInfo?.courseAttributes?.coursename || '' }} />
                                                                        <div className='powered-by'>
                                                                                {globalStyle?.poweredby !== "*" && <img src={DataHelper.getResourcePath(0, globalStyle?.poweredby) || PoweredBy} alt='' className='powered-img' />}
                                                                                <span className='powered-img' onClick={() => findStructureIssue(courseInfo?.courseOverivew[0] || [])}>&nbsp;&nbsp;</span>
                                                                        </div>
                                                                        <div className="btn-holder">
                                                                                <AppButton style={{ fontWeight: 'bold', minWidth: '230px', padding: '14px 16px' }} theme={"primary2"} disabled={trackInfo?.courseTracking?.lock_course || isPublished()} tabIndex={1} onClick={() => { handleClick() }}>{ButtonText}</AppButton>
                                                                                {globalStyle?.headers?.c && <button className="details print-map-btn br-5"  onClick={(e) => printCourseMap(e, 'CourseMap', '80%')}>
                                                                                        <span>{t("P_CRS_MAP")}</span>
                                                                                </button>}
                                                                                {/* <div className="details" onClick={ () => goToSection() }><p><span>Course Overview <ArrowDropDown className="arrow-down" /></span></p></div> */}
                                                                        </div>
                                                                </div>}
                                                                {courseInfo.loading && <div className="loader-info">{t("LOADING")}....</div>}
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                                <div className="crs-content" >
                                        {/* <img src={require('../../assets/img/logo.svg').default} alt='logo' className="logo"/> */}
                                        <p>
                                                {getCourseName() || ""}
                                        </p>
                                </div>

                                <div className="crs-content menu" ref={detailSection}>
                                        {courseInfo.loading && <div className="loader-info">{t("LOADING")} ....</div>}
                                        {!courseInfo.loading &&
                                                <div className={`${classes.root} course-overview`}>
                                                        <div>
                                                                <AppBar position="static">
                                                                        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                                                                                <Tab title={'Course Contents'} label={t('COURSE_CONTENTS')} {...a11yProps(0)} />
                                                                                {/* <Tab label="CE Notes" { ...a11yProps( 1 ) } />
                                                                        <Tab label="Updates" { ...a11yProps( 2 ) } /> */}
                                                                        </Tabs>
                                                                </AppBar>
                                                                <TabPanel value={value} index={0} >
                                                                        <TreeMenu isHome={true} />
                                                                </TabPanel>
                                                                {/* <TabPanel value={ value } index={ 1 }>
                                                        CE Notes
                                                        </TabPanel>
                                                        <TabPanel value={ value } index={ 2 }>
                                                        Updates
                                                        </TabPanel> */}
                                                        </div>
                                                </div>}
                                </div>
                        </div>
                )
        } 


        const getHomePage=()=>{
                try{
                        switch (globalStyle?.theme) {
                                case 'ce_theme':
                                return ceHome()
                                case 'skill_theme':
                                return <SkillHome 
                                img={getImagePath()} 
                                homewrapper={homewrapper}
                                btnText={ButtonText}
                                disabled={trackInfo?.courseTracking?.lock_course || isPublished()}
                                onClick={()=>handleClick()}
                                bgContent={bgContent} crsName={getCourseName()}
                                crsMap={(e)=>{printCourseMap(e, 'CourseMap', '60%')}}
                                />
                                default:
                                return ceHome();
                        }
          }catch(e){}
        }

        /**
         * Display the pre-test feature based on global settings.
         * 
         * This function checks if the pre-test is enabled and if the user's progress is null.
         * If both conditions are met, the pre-test will be shown to the user.
         * If the user reaches 100% in the pre-test, the course will be marked as completed.
         * 
         */
        const preTestModel=useMemo(() => (
                 <CommonModal
                        isOpen={(isPreTest?.length > 0) && !Object.keys(hasAttestation ?? {})?.length > 0}
                        onClose={()=>handleClose()}
                        CloseButton={false}
                        className="test-out-modal"
                        // bgColor={"transparent"}
                        content={<div className='pre-test-container'>
                                {globalStyle?.pre_test?.t && <p className='pre-test-heading'>{globalStyle?.pre_test?.t}</p>}
                                 <Quizzes
                                  items={isPreTest}
                                  resetCourse= {() =>DataHelper.resetCourse(trackInfo,dispatch,null,courseTrackingAction,courseInfo)}
                                  sendQuizReport={ (payload) =>DataHelper.sendQuizReport(payload,courseTrackingAction,dispatch,userInfo)}
                                //   reDirect={(path)=>history.push(`${path}}`)}
                                  lockCourse={()=>DataHelper.lockCourse(dispatch,trackInfo,courseTrackingAction)}
                                  config={globalStyle?.pre_test?.d?.config ||null}
                                  pers={globalStyle?.pre_test?.d?.pers} isPreTest={true} pretestCourseCompletion={pretestCourseCompletion}  />
                                 </div>}
                        buttons={[
                                {text:'Skip', func:handleClose,},
                                {text:'Submit',
                                func:handleClose,
                                color:isPreTestCompleted ?  "#fff" : "#000",
                                bgColor:isPreTestCompleted ?  "#13984b" : "#a5a5a5",
                                isDisable:(!isPreTestCompleted)}
                        ]}
                        width="65%"
                        height="65%"
                        shadow="none"
                />
        ),[isPreTest, globalStyle, isPreTestCompleted, hasAttestation])

        /**
         * During the attestation popup, we listen for clicks on <a> tags.
         * If any <a> tag is present, the checkbox is disabled.
         * The checkbox is enabled only after the <a> tag is clicked and viewed.
         */
        const [hasClicked, setHasClicked] = useState(false)
        useEffect(() => {
                if(!globalStyle?.attest?.t) return;
                const container = document.querySelector(".attest-click-event");
                let clickedLinks = [];

                const handleLinkClick = (event) => {
                        const link = event.target.closest("a");
                        if (link) {
                                const href = link.getAttribute("href");
                                clickedLinks.push(link); // mark this link as clicked
                                if (clickedLinks.length === container.querySelectorAll("a").length) {
                                        // all links have been clicked
                                        setHasClicked(false);
                                }
                        }
                };

                if (container) {
                        const links = container.querySelectorAll("a");
                        setHasClicked(links.length > 0); // initially set if there are any <a> tags

                        container.addEventListener("click", handleLinkClick);
                } else {
                        setHasClicked(false); // no <a> tags if container is missing
                }

                return () => {
                        if (container) {
                                container.removeEventListener("click", handleLinkClick);
                        }
                };
        }, [globalStyle?.attest?.t]);

        /**
         * Pre-attestation modal component.
         * 
         * This modal is displayed based on the user's attestation status and a global configuration flag (`BuildHelper.hasPreAttestation()`).
         * 
         * Key features:
         * - The modal will only open if `hasAttestation?.is` is `true` and `BuildHelper.hasPreAttestation()` returns `true`.
         * - Provides a custom checkbox for user confirmation, which enables the "OK" button upon checking.
         * - Optimized use of the `useMemo` hook to avoid unnecessary re-renders.
         * 
         * Props:
         * - `hasAttestation`: Object that tracks the attestation state (`is` to display modal, `ok` for checkbox state).
         * - `t`: Function for translating text strings.
         * - `globalStyle`: Object containing global styles and content for the modal.
         * - `handleAttestClose`: Function to close the modal.
         * 
         */
        const preAttestationModel=useMemo(() =>{
           try{
                return ( <CommonModal
                       isOpen={hasAttestation?.is && !BuildHelper.hasPreAttestation()}
                       onClose={()=>handleAttestClose()}
                       CloseButton={false}
                       title={t("ATTESTATION")}
                       className="test-out-modal attestation-model"
                       // bgColor={"transparent"}
                       content={<>
                         {globalStyle?.attest?.t ? <div className='attest-click-event'  dangerouslySetInnerHTML={{ __html: globalStyle?.attest?.t||"" }} /> :
                            <p>{t("ATTESTATION_MSG")}</p>}
                       {/* <p  dangerouslySetInnerHTML={{ __html: globalStyle?.attest?.t||"" }} />
                       <p className='attest-act-text'>{t("ATTESTATION_MSG")}</p> */}
                       <CustomCheckbox
                        label={globalStyle?.attest?.c || t("ATTESTATION_LABEL")}
                        checked={Boolean(hasAttestation?.ok)}
                        disable={hasClicked}
                        name="n" 
                        className={"attest-check"}
                        onChange={(e) => setHasAttestation({...(hasAttestation??{}),ok:e?.target?.checked})}
                        /></>}
                       buttons={[
                               {text:t("ACCEPTTERMS"),
                               func:handleAttestClose,
                               color:hasAttestation?.ok ?  "#fff" : "#000",
                               bgColor:hasAttestation?.ok ?  "#13984b" : "#a5a5a5",
                               isDisable:(!hasAttestation?.ok)}
                       ]}
                       width="50%"
                       height="40%"
                       shadow="none"
               /> )
         }catch(e){

                console.error("error at preAttestationModel:",e)
        }
        },[hasAttestation,t("ATTESTATION"),hasClicked]);
       
        useEffect(() => {
                 /**
                 * Fetch and translations json data.
                 * 
                 * The function first checks if the course's translation.json data is already available.
                 * If not, it fetches the translations from the content folder and stores them in the state.
                 * 
                 */
                const fetchAndTransformLanguages = async () => {
                        try {
                                let translations = courseInfo?.transition;
                                if (!courseInfo?.transition) {
                                        translations = await DataHelper.fetchTranslations();
                                        dispatch(courseInfoAction.translationJson(translations))
                                }
                                // Map the language keys to create an array of language's based on auth player_config_json
                                const languagesArray = trackInfo?.crsProgress?.player_config_json?.i18n?.languages?.map((key) => {
                                        return {
                                                value: key?.lan,
                                                label: translations[key?.lan]?.LA_NAME || '',
                                                name: translations[key?.lan]?.LANGUAGE_N || '',
                                                obj: key
                                        }
                                })?.filter(e=>e?.label)
                                setLanguages(languagesArray);
                        } catch (error) {
                                console.error('Error fetching translations:', error);
                        }
                };
                /**
                 * Only fetch and translations json if:
                 * 1. No languages are currently set in state.
                 * 2. Language data exists in player_config_json auth.
                 * 3. The current user is a learner (as determined by BuildHelper).
                 */

                if (!languages?.length &&
                        trackInfo?.crsProgress?.player_config_json?.i18n?.languages?.length > 0 &&
                        BuildHelper.isLearner()) {
                        fetchAndTransformLanguages();
                }
        }, [trackInfo?.crsProgress, trackInfo])

        const handleLanguage = (obj) => {
                const defaultLn = trackInfo?.courseTracking?.lan || trackInfo?.crsProgress?.sco?.lan;
                const newLanguage = obj?.lan || selectedLan?.lan;
                let progress = trackInfo;
                let coursePayload = {};
                let crs_id= obj?.id?? selectedLan?.id;
                // If the language is changing
                if (defaultLn !== newLanguage) {
                        window.localStorage.setItem(`${BuildHelper.configId()}_LN`, JSON.stringify(newLanguage || 'en_US'));
                          // If no previous language is set, initialize i18n with the new language
                        if (!defaultLn) {
                                initializeI18n(newLanguage, courseInfo.transition).then(() => {
                                        console.log('i18n initialized successfully');
                                }).catch(error => {
                                        console.error('Error initializing i18n:', error);
                                });
                        } else {
                                // If a language is already set, change the language
                                changeLanguage(newLanguage, courseInfo.transition);
                                coursePayload = { isUpdate: true };
                        }

                        dispatch(courseInfoAction.requestCoursInfo({ ...coursePayload, id: crs_id }));

                        if (BuildHelper.isOnScorm()){
                              let progressScorm = {
                                        ...trackInfo,
                                        courseTracking: {
                                                ...(trackInfo?.courseTracking || {}),
                                                lan: newLanguage,
                                                topic:[],
                                                visited:{}
                                        }
                                }
                                let encrypted = trackInfo.scorm.encryptProgress(progressScorm?.courseTracking);
                                if(trackInfo?.courseTracking?.scorm){
                                        trackInfo.courseTracking.scorm = { sd: encrypted };
                                        trackInfo.scorm.setScormData(encrypted);
                                        trackInfo?.scorm.setLastAccessedTopic(null);
                                }
                                dispatch(courseTrackingAction.saveCourseProgress({...progressScorm}));

                        }

                        else if (trackInfo?.courseTracking) {
                                const trackData = DataHelper?.retakeProgressData(courseInfo.flatten);
                                progress = {
                                        ...trackInfo,
                                        courseTracking: {
                                                ...(trackInfo?.courseTracking || {}),
                                                ...trackData?.t,
                                                lan: newLanguage,
                                                visited:{}
                                        }
                                }
                                dispatch(courseTrackingAction.saveCourseProgress({...progress}));
                        }
                }
                dispatch(courseTrackingAction.hasLanguage());
        }

        const handleLanguageView = () => {
                dispatch(courseTrackingAction.hasLanguage());
        }

        const handleLanguageSelection = () => {
                setSelectedLan({})
        }

        const handleSelectLn = (obj) => {
                const currentLan = trackInfo?.courseTracking?.lan || trackInfo?.crsProgress?.sco?.lan;
                if (currentLan === obj?.lan){
                        handleLanguageView();
                        return;
                }
                if (currentLan) {
                        setSelectedLan({ ...obj })
                        return;
                }
                handleLanguage(obj);
                setSelectedLan({ ...obj })
        }

        const LanguagePanel = useMemo(() => {


                const currentLan = trackInfo?.courseTracking?.lan || trackInfo?.crsProgress?.sco?.lan;

                const getText = (key) => {
                        const jsonKey = key.toUpperCase();
                        return courseInfo?.transition ? courseInfo?.transition[selectedLan?.lan||currentLan || 'en_US'][jsonKey] : key;
                };

                /**
                 * based on the auth player_config_json language list
                 */
                return (
                        <CommonModal
                                isOpen={(trackInfo?.hasLanguage && BuildHelper.isLearner())}
                                onClose={handleLanguageView}
                                title={<MuiIcons iconName='translate' color="#1E3C88" preText={getText("LAN_CHOOSE")} />}
                                //"CHOOSE YOUR LANGUAGE"
                                className={`lan-sel-model ${currentLan && 'show-ln-close'}`}
                                content={
                                        <div className='language-container'>
                                                {/* <h2>{getText("LAN_CHOOSE")}</h2> */}
                                                <div className="modal-body languages-body">
                                                        <div className='row'>
                                                                {languages?.map((lan, index) => (
                                                                        <div key={index} className={`col-lg-3 col-md-12 col-sm-12`}>
                                                                                
                                                                                <div className={`language-box ${(selectedLan?.lan || currentLan) === lan?.value && 'lang-img-selected pb-2'}`} onClick={(e) => handleSelectLn(lan?.obj)}>
                                                                                       <div className='flag-box py-2'> <span className='flag'>
                                                                                        <FlagIcon code={lan?.value} />
                                                                                        </span>
                                                                                        <p className='land-name m-0'>{lan?.name}</p> </div>
                                                                                        <div className='ng-binding py-2'>{lan?.label}</div>
                                                                                </div>
                                                                        </div>
                                                                ))}
                                                        </div>
                                                </div>
                                                {(selectedLan?.lan && currentLan) && <CustomSweetAlert
                                                        warning
                                                        showCancel
                                                        show={selectedLan?.lan}
                                                        confirmBtnText={getText('OK')}
                                                        cancelBtnText={getText('CANCEL')}
                                                        confirmBtnBsStyle="danger"
                                                        title={<p className="sweet-title">{courseInfo?.transition ?
                                                                courseInfo?.transition[selectedLan?.lan || 'en_US']['LANGUAGE_POP_WARNING'] :
                                                                'If you proceed, your progress will be completely reset. Are you sure you want to continue?'}</p>}
                                                        onConfirm={() => handleLanguage()}
                                                        onCancel={handleLanguageSelection}
                                                />}
                                        </div>
                                }
                                width="85%"
                                height="80%"
                                shadow="none"
                        />
                );
        }, [trackInfo?.hasLanguage, trackInfo?.crsProgress, languages, selectedLan]);



        return (
                <>
                {(courseInfo?.isLoading || !t('START')?.length > 0 || globalStyle?.isLoading) && <Loader /> }
                 { getHomePage()}
                <div className={`warning-popup ${!isCEAuthor() && 'application-info'} `}>

                        <CustomSweetAlert
                                warning
                                show={showAlert}
                                title={""}
                                onConfirm={handleOk}
                                confirmBtnText={isCEAuthor() ? t("OK") : t("CLOSE")}
                                children={infoInformation}
                        />
                        <CustomSweetAlert
                                warning
                                show={[1].includes(utility?.socketWarning)}
                                title={warning[utility?.socketWarning]?.title||""}
                                onConfirm={()=>warning[utility?.socketWarning]?.function()}
                                children={warning[utility?.socketWarning]?.content||""}
                                confirmBtnText={warning[utility?.socketWarning]?.cButton||""}
                        />

                        <CustomSweetAlert
                                warning
                                show={utility?.authFault}
                                title={t("S_WRONG")}
                                onConfirm={handleAuth}
                                children={t("RE_LOGIN")}
                        />
                      {isPreTest?.length > 0 && preTestModel}
                      {preAttestationModel}
                      {LanguagePanel}
                </div></>);

}

export default withRouter(Home)